
export const routerLinks = {
    //admin
    Dashboard: '/',

    //KOLs Admin
    KOLsManager: '/kols-manager',
    WithdrawalsRequest: '/withdrawals-request',
    PolicyManager: '/policy-manager',
    CommissionFeeManager: '/commission-fee',
    CouponManager: '/coupon-manager',
    StatisticsTopKOLManager: '/statistics-topKOLs',
    StatisticsKOLManager: '/statistics-KOLs',
    StatisticsOrderManager: '/statistics-order',

    //KOLs
    KOLsProfile: '/kols-profile',
    KOLsStatisticsFee: '/kols-statistics-fee',
    KOLsStatisticsUser: '/kols-statistics-user',
    KOLsWithdrawalsHistory: '/kols-withdrawals-history',
    KOLsWithdrawalsRequest: '/kols-withdrawals-request',
    KOLsPolicy: '/kols-policy',
    AllReferals: '/all-referals',
    PointReferals: '/point-referals',

    Banner: '/banner',

    //all
    Form: '/form',
    Calendar: '/calendar',
    Faq: '/faq',
    Bar: '/bar',
    Pie: '/pie',
    Line: '/line',
    Geography: '/geography',
    Login: '/login',
}

export const m_routerLinks = {
    M_Dashboard: '/',
    M_Settings: '/setting-general',
}
