import { Cancel, Close, Send } from '@mui/icons-material'
import { Modal, Box, Typography, IconButton, TextField, useTheme, Button } from '@mui/material'
import { Formik } from 'formik'
import { Space, Upload, Button as ButtonAntd, Image } from 'antd'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { tokens } from '../../theme'
import { baseURL } from '../../utils/api/baseURL'
import { useEffect } from 'react'
import CustomLoadingComponent from '../../components/loading'
import TextFieldCustom from '../../components/form/textfield'
import ModalCustom from '../../components/modal/modal'

function ModalBanner({
    type,
    open,
    onClose,
    isLoading,
    handlePreview,
    previewOpen,
    setPreviewOpen,
    setPreviewImage,
    previewImage,
    formState,
    validateSchema,
    beforeUpload,
    handleConfirm,
}) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    useEffect(() => {
        if (type === 'edit' && formState.thumbnail) {
            setPreviewImage(formState.thumbnail)
            // setPreviewOpen(true)
        }
    }, [type, formState.thumbnail])

    const styleBox = {
        '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '80%',
            '& .MuiTextField-root': {
                flex: 1,
            },
        },
        '& .MuiTypography-root': {
            minWidth: '120px',
            fontWeight: 600,
            fontSize: 14,
        },
        '& .MuiBox-root.select': {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        // maxHeight: '700px',
        maxHeight: '70vh',
        overflowY: 'auto',
        pb: '10px',
        pt: '20px',
        backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : '#e7e7e7',
        borderRadius: '5px',
        '& .Mui-focused fieldset': {
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6) !important' : 'rgba(0, 0, 0, 0.8)', // Màu border khi focused
            borderWidth: theme.palette.mode === 'dark' ? '2px' : '1px !important',
        },
    }

    return (
        <ModalCustom
            open={open}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    return
                }
                onClose()
            }}
            style={{ zIndex: '99' }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h4' mb={'10px'} color={colors.grey[300]}>
                    {type === 'create' ? 'TẠO BANNER' : 'CHỈNH SỬA BANNER'}
                </Typography>
            </Box>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                }}
                onClick={onClose}
            >
                <Close color='disabled' />
            </IconButton>

            <Box>
                <Formik enableReinitialize={true} initialValues={formState} validationSchema={validateSchema} onSubmit={handleConfirm}>
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => {
                        return (
                            <Box component='form' display='flex' flexDirection='column' sx={styleBox} onSubmit={handleSubmit}>
                                {isLoading && (
                                    <Box
                                        position='absolute'
                                        top={0}
                                        left={0}
                                        right={0}
                                        bottom={0}
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        bgcolor='rgba(255, 255, 255, 0.2)'
                                        zIndex={2}
                                        width='100% !important'
                                    >
                                        <CustomLoadingComponent />
                                    </Box>
                                )}
                                <TextFieldCustom
                                    label='Tiêu đề'
                                    name='title'
                                    value={values.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!touched.title && !!errors.title}
                                    helperText={touched.title && errors.title}
                                    widthField='80%'
                                />
                                <TextFieldCustom
                                    label='Mô tả'
                                    name='description'
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!touched.description && !!errors.description}
                                    helperText={touched.description && errors.description}
                                    widthField='80%'
                                />
                                <TextFieldCustom
                                    label='Mã khuyến mãi'
                                    name='codeDiscount'
                                    value={values.codeDiscount}
                                    onChange={handleChange}
                                    helperText={<Typography fontSize={'10px'}>Không bắt buộc (Có thể dùng để áp dụng giảm giá)</Typography>}
                                    widthField='80%'
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        gap: { xs: '10px!important', sm: '20px!important' },
                                        alignItems: { xs: 'flex-start!important', sm: 'center!important' },
                                        width: { xs: '100% !important', sm: '80% !important' },
                                        '& .boxUpload': {
                                            '& .ant-upload-list-item-name': {
                                                color: colors.grey[100],
                                            },
                                            '& .anticon.anticon-delete': {
                                                color: `${colors.grey[100]} !important`,
                                            },
                                        },
                                    }}
                                >
                                    <Typography>Ảnh banner: </Typography>
                                    <Space
                                        direction='vertical'
                                        style={{
                                            width: '100%',
                                            rowGap: 0,
                                        }}
                                        className='boxUpload'
                                        size='large'
                                    >
                                        <Upload
                                            onRemove={() => {
                                                setFieldValue('thumbnail', null)
                                                setPreviewImage(null)
                                            }}
                                            beforeUpload={(file) => beforeUpload(file, setFieldValue)}
                                            listType='picture-card'
                                            maxCount={1}
                                            fileList={
                                                previewImage
                                                    ? [
                                                          {
                                                              uid: '-1',
                                                              //   name: 'image.png',
                                                              status: 'done',
                                                              url: `${baseURL}uploads/${previewImage}`,
                                                          },
                                                      ]
                                                    : []
                                            }
                                            onPreview={handlePreview}
                                        >
                                            <button
                                                style={{
                                                    border: 0,
                                                    background: 'none',
                                                }}
                                                type='button'
                                            >
                                                <PlusOutlined style={{ color: colors.grey[400] }} />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                        color: colors.grey[400],
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </button>
                                        </Upload>
                                        {previewImage && (
                                            <Image
                                                wrapperStyle={{
                                                    display: 'none',
                                                }}
                                                preview={{
                                                    visible: previewOpen,
                                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                                }}
                                                src={`${baseURL}uploads/${previewImage}`}
                                            />
                                        )}
                                        {touched.thumbnail && errors.thumbnail && (
                                            <Typography color='error' variant='caption' fontSize='11px !important'>
                                                {errors.thumbnail}
                                            </Typography>
                                        )}
                                        {/* Ghi chú về kích thước */}
                                        <Typography variant='caption' color='textSecondary'>
                                            Kích thước yêu cầu: Rộng 1024px x Cao 500px
                                        </Typography>
                                    </Space>
                                </Box>

                                <Box justifyContent='end' mt='20px' sx={{ width: { xs: '100% !important', sm: '80% !important' } }}>
                                    <Button
                                        sx={{ minWidth: '96px', p: '8px 16px' }}
                                        type='submit'
                                        variant='contained'
                                        color='success'
                                        endIcon={<Send />}
                                    >
                                        Gửi
                                    </Button>
                                    <Button
                                        sx={{ minWidth: '96px', p: '8px 16px' }}
                                        variant='outlined'
                                        color='inherit'
                                        startIcon={<Cancel />}
                                        onClick={onClose}
                                    >
                                        Huỷ
                                    </Button>
                                </Box>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
        </ModalCustom>
    )
}

export default ModalBanner
