import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, useTheme, Chip, useMediaQuery } from '@mui/material'
import { tokens } from '@src/theme'
import { kol_get_pending_point } from '@utils/api/apiList'
import { message } from 'antd'
import Header from '@components/Header'
import StyleDataGrid from '@assets/styles/styleDataGrid'
import request from '@utils/api/request'
import moment from 'moment'
import DataGridCustom from '@src/components/datagrid'
import useQueryOptions from '@src/components/datagrid/useQueryOptions'

const PointReferals = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches = useMediaQuery("(min-width:900px)");
    const [totalRows, setTotalRows] = useState(0);
    const [dataForTable, setDataForTable] = useState(null);
    const [loading, setLoading] = useState(false);
    //sort server
    const [queryOptions, handleSortModelChange] = useQueryOptions({
        createdDate: -1,
    });

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });

    //message
    const [messageApi, messageContextHolder] = message.useMessage();

    //fetching
    useEffect(() => {
        fetchDataAffiliate();
    }, [paginationModel, queryOptions]);

    //fetch data
    const fetchDataAffiliate = () => {
        setLoading(true);

        request
            .post(kol_get_pending_point, {
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                sort: queryOptions,
            })
            .then((res) => {
                const data = res.data.data;
                const convertData = data.docs.map((elm, index) => {
                    return {
                        ...elm,
                        index: index + 1 + paginationModel.page * paginationModel.pageSize,
                        dateConvert: moment(elm.createdDate).format("MM-DD-YYYY"),
                    };
                });

                setDataForTable(convertData);
                setTotalRows(data.total);

                // console.log(convertData)
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    //column
    const columns = useMemo(
        () =>
            [
                {
                    field: "index",
                    headerName: "STT",
                    width: 70,
                    sortable: false,
                },
                {
                    field: "fullName",
                    headerName: "Tên tài khoản",
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                    renderCell: ({ row }) => {
                        return row?.userId?.fullName
                    },
                },
                {
                    field: "phoneNumber",
                    headerName: "Số điện thoại",
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                    renderCell: ({ row }) => {
                        return row?.userId?.phoneNumber
                    },
                },
                {
                    field: "firstOrderCreatedAt",
                    headerName: "Ngày hoàn thành đơn",
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                    renderCell: ({ row }) => {
                        return moment(row?.firstOrderCreatedAt).format('hh:mm - dd/mm/yyyy')
                    },
                },
                {
                    field: "waitBonusByOrder",
                    headerName: "Điểm thưởng chờ nhận",
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                    renderCell: ({ row }) => {
                        return row?.status == 0 ? Intl.NumberFormat ('en-US').format(row?.bonusByOrder) : 0
                    },
                },
                {
                    field: "bonusByOrder",
                    headerName: "Điểm đã nhận",
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                    renderCell: ({ row }) => {
                        return row?.status == 0 ? 0 : Intl.NumberFormat ('en-US').format(row?.bonusByOrder)
                    },
                }
            ].filter(Boolean),
        [dataForTable]
    );

    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='QUẢN LÝ ĐIỂM THƯỞNG TỪ NGƯỜI GIỚI THIỆU' subtitle='Trang quản lý người giới thiệu có chiết khấu điểm thưởng' />
            </Box>
            <Box
                height='75vh'
                m='40px 0 0 0'
                sx={{
                    ...StyleDataGrid(),
                    '& .maxValue': {
                        color: theme.palette.mode === 'dark' ? '#44c144' : '#49ac49',
                    },
                }}
            >
                <DataGridCustom
                    rows={dataForTable}
                    rowCount={totalRows}
                    columns={columns}
                    initialState={{
                        pagination: { paginationModel },
                    }}
                    onPaginationModelChange={setPaginationModel}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    disableColumnFilter
                />
            </Box>
        </Box>
    )
}

export default PointReferals
