import React, { useEffect, useMemo, useState } from 'react'
import { Box, useTheme, Button, Chip } from '@mui/material'
import { tokens } from '@src/theme'
import { kol_get_withdrawal_request } from '@utils/api/apiList'
import { message } from 'antd'
import EmojiFlagsOutlinedIcon from '@mui/icons-material/EmojiFlagsOutlined'
import Header from '@components/Header'
import StyleDataGrid from '@assets/styles/styleDataGrid'
import StyleButtonGradient from '@assets/styles/styleButtonGradient'
import request from '@utils/api/request'
import moment from 'moment'
import * as yup from 'yup'
import ModalWithdrawalsRequest from './ModalWithdrawalsRequest'
import DataGridCustom from '@src/components/datagrid'
import useQueryOptions from '@src/components/datagrid/useQueryOptions'
import { useLocation } from 'react-router-dom'

const KOLsWithdrawalsRequest = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const role = localStorage.getItem('role')
    const location = useLocation()
    const [dataForTable, setDataForTable] = useState(null)
    const [ancholElAdd, setAncholElAdd] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    //message
    const [messageApi, messageContextHolder] = message.useMessage()

    const [loading, setLoading] = useState(false)
    // pagination server side
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    })
    const [totalRows, setTotalRows] = useState(0)
    //sort server
    const [queryOptions, handleSortModelChange] = useQueryOptions({
        createdAt: -1,
    });

    useEffect(() => {
        fetchData()
    }, [paginationModel, queryOptions])

    useEffect(() => {
        if (!!location?.state?.requestPoint) {
            setAncholElAdd(true)
        }
      }, [location?.state?.requestPoint]);

    //fetch data
    const fetchData = () => {
        setLoading(true)

        request
            .post(kol_get_withdrawal_request, {
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
                sort: queryOptions,
            })
            .then((res) => {
                const data = res.data.data
                const onlyRequestData = !!data && data?.length > 0 && data.filter((item) => item.status !== 1)
                const dataConvert =
                    !!onlyRequestData &&
                    onlyRequestData?.length > 0 &&
                    onlyRequestData
                        .map((elm, index) => {
                            return {
                                ...elm,
                                index: index + 1 + paginationModel.page * paginationModel.pageSize,
                                dateConvert: moment(elm.createdAt).format('MM-DD-YYYY'),
                            }
                        })

                setDataForTable(dataConvert)
                setTotalRows(data?.total)

                // console.log(convertData)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    //column
    const columns = useMemo(
        () =>
            [
                {
                    field: 'index',
                    headerName: 'STT',
                    width: 70,
                    sortable: false,
                },
                {
                    field: 'createdAt',
                    headerName: 'Ngày yêu cầu',
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                    renderCell: ({ row }) => {
                        return moment(row?.createdAt).format('hh:mm - DD/MM/YYYY')
                    },
                },
                {
                    field: 'amount',
                    headerName: 'Số điểm',
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                    renderCell: ({ row }) => {
                        return Intl.NumberFormat('en-US').format(row?.amount)
                    },
                },
                {
                    field: 'status',
                    headerName: 'Trạng thái xử lý',
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                    renderCell: ({ row }) => {
                        return (
                            <Box>{
                                row?.status === 0 && (
                                    <Chip label='Chờ duyệt' size='medium' sx={{ backgroundColor: '#0091ea', minWidth: '90px', fontSize: '12px' }} />
                                )
                            }
                                {
                                    row?.status === 1 && (
                                        <Chip label='Chấp nhận' size='medium' sx={{ backgroundColor: '#4caf50', minWidth: '90px', fontSize: '12px' }} />
                                    )
                                }
                                {
                                    row?.status === 2 && (
                                        <Chip label='Từ chối' size='medium' sx={{ backgroundColor: '#ff5722', minWidth: '90px', fontSize: '12px' }} />
                                    )
                                }</Box>)
                    },
                },
                {
                    field: 'reason',
                    headerName: 'Lý do(chấp nhận/từ chối)',
                    minWidth: 150,
                    flex: 1,
                    sortable: false,
                },
            ].filter(Boolean),
        [dataForTable]
    )

    const handleOpenBoxCreate = (e) => {
        setAncholElAdd(e.currentTarget)
    }

    const handleCloseBoxCreate = () => {
        setAncholElAdd(false)
    }

    const handleSubmitRequest = () => {
        setAncholElAdd(false)
        fetchData()
    }
    return (
        <Box
            m='20px'
            sx={{
                '& .MuiTypography-root.MuiTypography-h2': {
                    margin: '0 0 5px 0',
                },
            }}
        >
            {messageContextHolder}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Header title='YÊU CẦU RÚT TIỀN' subtitle='Trang xem trạng thái yêu cầu rút tiền' />
                <Button sx={StyleButtonGradient()} onClick={(e) => handleOpenBoxCreate(e)}>
                    <EmojiFlagsOutlinedIcon sx={{ mr: '10px' }} />
                    GỬI YÊU CẦU
                </Button>
            </Box>
            <Box
                height='75vh'
                m='40px 0 0 0'
                sx={{
                    ...StyleDataGrid(),
                    '& .maxValue': {
                        color: theme.palette.mode === 'dark' ? '#44c144' : '#49ac49',
                    },
                }}
            >
                <DataGridCustom
                    rows={dataForTable}
                    rowCount={totalRows}
                    columns={columns}
                    initialState={{
                        pagination: { paginationModel },
                        // density: 'comfortable',
                    }}
                    onPaginationModelChange={setPaginationModel}
                    //pagination server side
                    // sort server
                    sortingMode='server'
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    disableColumnFilter
                />
            </Box>

            {!!ancholElAdd && <ModalWithdrawalsRequest
                open={Boolean(ancholElAdd)}
                onClose={handleCloseBoxCreate}
                onSubmitRequest={handleSubmitRequest}
                isLoading={isLoading}
            />}
        </Box>
    )
}

export default KOLsWithdrawalsRequest
