const images = {
    av2: require('../images/hotGirl2.jpeg'),
    job: require('../images/job1.webp'),
    house: require('../images/house.webp'),
    icon: require('../images/favicon.png'),
    placeholderPerson: require('../images/placeholderPerson.jpeg'),
    qr_example: require('../images/qr_example.png'),
    // file svg cần .default , file png thì không
    // svg1: require('../images/svg/svg-1.svg').default,
}

export default images
