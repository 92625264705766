import { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { message } from "antd";
import {
  total_user_ref_from_date_to_date,
  total_point_get_from_date_to_date,
} from "../../../utils/api/apiList";
import { formatNumber } from "../../../components/prefixData";
import { tokens } from "../../../theme";
import { DatePicker as DatePickerAntd } from "antd";
import Header from "../../../components/Header";
import request from "../../../utils/api/request";
import LineChart from "../../../components/LineChart";
import dayjs from "dayjs";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import "moment/locale/vi";

function KOLsStatisticsFee() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dataRefConvert, setDataRefConvert] = useState([]);
  const [dataPointConvert, setDataPointConvert] = useState([]);
  const [totalRef, setTotalRef] = useState(0);
  const [totalPoint, setTotalPoint] = useState(0);
  const [dateRange, setDateRange] = useState([dayjs().add(-7, "d"), dayjs()]);
  const [startDate, setStartDate] = useState(dayjs().add(-7, "d"));
  const [endDate, setEndDate] = useState(dayjs());
  const [previousCompany, setPreviousCompany] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const matches1400 = useMediaQuery("(min-width:1400px)");
  console.log("dayjs", dayjs);

  //fetch data ref date by date
  const fetchRefDateByDate = (data) => {
    console.log("giang nè", data);
    request
      .post(total_user_ref_from_date_to_date, { ...data })
      .then((res) => {
        const totalDateByDate = res.data.data;
        console.log("data chart date by date", totalDateByDate, res.data.data);

        //data reset
        const dataResetChart = [
          {
            _id: {
              day: dayjs(endDate).date(),
              month: dayjs(endDate).month() + 1,
              year: dayjs(endDate).year(),
            },
            totalUser: 0,
          },
          {
            _id: {
              day: dayjs(startDate).date(),
              month: dayjs(startDate).month() + 1,
              year: dayjs(startDate).year(),
            },
            totalUser: 0,
          },
        ];

        // hàm converChartDataDateByDate
        !!totalDateByDate && totalDateByDate.length > 0
          ? convertChartRefDateByDate(totalDateByDate)
          : convertChartRefDateByDate(dataResetChart);
      })
      .catch((err) => {
        if (!!err) {
          console.error("Loi lay du lieu chart doanh thu cong ty", err);
          message.error(
            "Lỗi: không thể cập nhật dữ liệu, vui lòng thử lại sau!"
          );
        }
      });
  };

  //fetch data point date by date
  const fetchPointDateByDate = (data) => {
    console.log("giang nè", data);
    request
      .post(total_point_get_from_date_to_date, { ...data })
      .then((res) => {
        const totalDateByDate = res.data.data;
        console.log("data chart date by date", totalDateByDate, res.data.data);

        //data reset
        const dataResetChart = [
          {
            _id: {
              day: dayjs(endDate).date(),
              month: dayjs(endDate).month() + 1,
              year: dayjs(endDate).year(),
            },
            totalBonus: 0,
          },
          {
            _id: {
              day: dayjs(startDate).date(),
              month: dayjs(startDate).month() + 1,
              year: dayjs(startDate).year(),
            },
            totalBonus: 0,
          },
        ];

        // hàm converChartDataDateByDate
        !!totalDateByDate && totalDateByDate.length > 0
          ? convertChartPointDateByDate(totalDateByDate)
          : convertChartPointDateByDate(dataResetChart);
      })
      .catch((err) => {
        if (!!err) {
          console.error("Loi lay du lieu chart doanh thu cong ty", err);
          message.error(
            "Lỗi: không thể cập nhật dữ liệu, vui lòng thử lại sau!"
          );
        }
      });
  };

  useEffect(() => {
    let startDateNew = startDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    let endDateNew = endDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    fetchRefDateByDate({
      startDate: startDateNew,
      endDate: endDateNew,
    });
    fetchPointDateByDate({
      startDate: startDateNew,
      endDate: endDateNew,
    });
  }, [startDate, endDate]);

  //handle change range
  const { RangePicker } = DatePickerAntd;
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setDateRange(dates);
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      fetchRefDateByDate({ startDate: dates[0], endDate: dates[1] });
      fetchPointDateByDate({ startDate: dates[0], endDate: dates[1] });
    } else {
      console.log("Clear");
      setDateRange([]);
      setStartDate(null);
      setEndDate(null);
    }
  };
  const rangePresets = [
    {
      label: "7 ngày trước",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "14 ngày trước",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "30 ngày trước",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "90 ngày trước",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  useEffect(() => {
    console.log("dataRefConvert", dataRefConvert);
    console.log("dataPointConvert", dataPointConvert);
  }, [dataRefConvert, dataPointConvert]);

  // Hàm chuyển đổi dữ liệu từ API thành format của Nivo Line Chart (theo thời điểm từ ngày đến ngày)
  const convertChartRefDateByDate = (apiData) => {
    const refData = {
      id: "Người giới thiệu",
      data: [],
    };
    let totalRef = 0;

    // Duyệt qua từng phần tử trong dữ liệu trả về từ API
    apiData?.forEach((entry) => {
      const { day, month, year } = entry._id;
      const dateLabel = `${day}/${month}/${year}`; // Định dạng nhãn trục X theo ngày/tháng/năm
      const refItem = entry.totalUser;

      // Đẩy dữ liệu doanh thu và đơn hàng vào mảng theo format của Nivo Line Chart
      refData.data.push({ x: dateLabel, y: refItem });

      // Tính tổng doanh thu và đơn hàng
      totalRef += refItem;
    });

    const valueInRef = dataRefConvert.findIndex(
      (item) => item.id === refData.id
    );

    if (valueInRef >= 0) {
      dataRefConvert[valueInRef].data = refData.data;
      setDataRefConvert([...dataRefConvert]);
    } else {
      // Nếu không có item nào trùng, thêm refData mới vào dataRefConvert
      setDataRefConvert([...dataRefConvert, refData]);
    }
    setTotalRef(totalRef);
  };
  const convertChartPointDateByDate = (apiData) => {
    const pointData = {
      id: "Điểm thưởng",
      data: [],
    };
    let totalPoint = 0;

    // Duyệt qua từng phần tử trong dữ liệu trả về từ API
    apiData?.forEach((entry) => {
      const { day, month, year } = entry._id;
      const dateLabel = `${day}/${month}/${year}`; // Định dạng nhãn trục X theo ngày/tháng/năm
      const pointItem = entry.totalBonus;

      // Đẩy dữ liệu doanh thu và đơn hàng vào mảng theo format của Nivo Line Chart
      pointData.data.push({ x: dateLabel, y: pointItem / 1000000 });

      // Tính tổng doanh thu và đơn hàng
      totalPoint += pointItem;
    });

    const valueInPoint = dataPointConvert.findIndex(
      (item) => item.id === pointData.id
    );

    if (valueInPoint >= 0) {
      dataPointConvert[valueInPoint].data = pointData.data;
      setDataPointConvert([...dataPointConvert]);
    } else {
      setDataPointConvert([...dataPointConvert, pointData]);
    }
    setTotalPoint(totalPoint);
  };

  return (
    <Box
      m="20px"
      mb="50px"
      sx={{
        "& .MuiTypography-root.MuiTypography-h2": {
          margin: "0 0 5px 0",
        },
        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.grey[100],
          borderWidth: "1px",
        },
        "& .MuiInputLabel-root.Mui-focused": { color: colors.grey[100] },
        "& .ant-picker-range-separator .ant-picker-separator .anticon.anticon-swap-right svg":
          {
            color: colors.greenAccent[100],
          },
        "& .ant-picker .ant-picker-input >input": {
          color: colors.grey[100],
          fontSize: "13px",
        },
        "& .ant-picker-large .ant-picker-input>input::placeholder": {
          color: colors.grey[100],
          fontSize: "13px",
        },
        "& .ant-picker-suffix .anticon.anticon-calendar svg": {
          color: colors.grey[100],
        },
        "& .ant-picker-clear .anticon.anticon-close-circle svg": {
          color: colors.grey[100],
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="THỐNG KÊ"
          subtitle="Trang thống kê người giới thiệu và điểm thưởng"
        />
      </Box>

      <Box height="75vh" m="40px 0 0 0">
        <Box
          gap={"20px"}
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", md: "space-between" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {/* Date Range Pickers khi chọn "Theo thời điểm" */}
          <Box sx={{ width: { xs: "100%", md: "230px" } }}>
            <RangePicker
              size="large"
              presets={rangePresets}
              onChange={onRangeChange}
              style={{
                padding: "15px",
                backgroundColor: colors.primary[500],
                width: "100%",
              }}
              placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
              value={dateRange}
              sx={{ width: "100%" }}
            />
          </Box>
          <Box mr={"150px"}>
            <Box fontWeight={600} color={colors.grey[100]}>
              NGƯỜI GIỚI THIỆU :{" "}
              <Typography
                fontWeight={700}
                display="inline-block"
                variant="h4"
                color={colors.greenAccent[500]}
              >
                {!!totalRef && totalRef > 0 ? formatNumber(totalRef) : 0}
              </Typography>
            </Box>
            <Box fontWeight={600} color={colors.grey[100]}>
              TỔNG ĐIỂM THƯỞNG :{" "}
              <Typography
                fontWeight={700}
                display="inline-block"
                variant="h4"
                color={colors.greenAccent[500]}
              >
                {!!totalPoint && totalPoint > 0 ? formatNumber(totalPoint) : 0}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Biểu đồ doanh thu theo nam */}
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(12, 1fr)",
            sm: "repeat(12, 1fr)",
            md: "repeat(12, 1fr)",
            lg: "repeat(12, 1fr)",
          }}
          gap="20px"
          sx={{ height: "100%", marginBottom: "20px" }}
        >
          {/* Biểu đồ doanh thu theo thời điểm */}
          {dataRefConvert.length > 0 && (
            <Box
              gridColumn={{ xs: "span 12", xl: "span 6" }}
              sx={{ maxHeight: { xs: "100%", lg: "450px" } }}
            >
              <LineChart
                multiLine={true}
                dataChart={dataRefConvert}
                nameChart="refDataByDate"
                companyName={null}
                selectedDate={
                  dayjs(endDate).format("DD/MM/YYYY") +
                  " - " +
                  dayjs(startDate).format("DD/MM/YYYY")
                }
              />
            </Box>
          )}
          {dataPointConvert.length > 0 && (
            <Box
              gridColumn={{ xs: "span 12", xl: "span 6" }}
              sx={{ maxHeight: { xs: "100%", lg: "450px" } }}
            >
              <LineChart
                multiLine={true}
                dataChart={dataPointConvert}
                nameChart="pointDataByDate"
                companyName={""}
                selectedDate={
                  dayjs(endDate).format("DD/MM/YYYY") +
                  " - " +
                  dayjs(startDate).format("DD/MM/YYYY")
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default KOLsStatisticsFee;
