const openMessageNotification = (messageApi, type, eventName, data) => {
    let description
    const notificationColor = type === 'success' ? 'green' : 'red'

    const notificationStyle = {
        color: notificationColor,
        fontWeight: 'bold',
    }

    if (type === 'success') {
        switch (eventName) {
            case 'cancel':
                description = (
                    <>
                        Đã huỷ đơn hàng <span style={notificationStyle}>{data}</span> thành công!
                    </>
                )
                break
            case 'asignPartner':
                description = (
                    <>
                        Gán nhân viên <span style={notificationStyle}>{data}</span> thành công
                    </>
                )
                break
            case 'switchPartner':
                description = (
                    <>
                        Đổi nhân viên <span style={notificationStyle}>{data}</span> thành công
                    </>
                )
                break
            case 'updateProfileAccountCompany':
                description = (
                    <>
                        Cập nhật hồ sơ admin <span style={notificationStyle}>{data}</span> thành công
                    </>
                )
                break
            case 'updateProfileKOLs':
                description = (
                    <>
                        Cập nhật thông tin thành công
                    </>
                )
                break
            case 'changePasswordAccountCompany':
                description = (
                    <>
                        Thay đổi mật khẩu admin <span style={notificationStyle}>{data}</span> thành công
                    </>
                )
                break
            case 'capnhatdonhang':
                description = <>Cập nhật đơn hàng thành công!</>
                break
            case 'editBankPartner':
                description = (
                    <>
                        Cập nhật thông tin ngân hàng của <span style={notificationStyle}>{data}</span> thành công!
                    </>
                )
                break
            case 'deleteBankPartner':
                description = (
                    <>
                        Xoá tài khoản ngân hàng của <span style={notificationStyle}>{data}</span> thành công!
                    </>
                )
                break
            default:
                break
        }
    } else {
        switch (eventName) {
            case 'delete':
                description = (
                    <>
                        Xoá đơn hàng <span style={notificationStyle}>{data}</span> thất bại!
                    </>
                )
                break
            case 'asignPartner':
                description = 'Gán nhân viên thất bại, vui lòng thử lại'
                break
            case 'switchPartner':
                description = 'Đổi nhân viên thất bại, vui lòng thử lại'
                break
            case 'updateProfileAccountCompany':
                description = 'Cập nhật hồ sơ admin thất bại, vui lòng thử lại'
                break
            case 'changePasswordAccountCompany':
                description = (
                    <p>
                        Lỗi <span style={notificationStyle}>{data}</span>, vui lòng thử lại
                    </p>
                )
                break
            case 'capnhatdonhang':
                description = 'Cập nhật thất bại, vui lòng thử lại'
                break
            case 'editBankPartner':
                description = (
                    <>
                        Cập nhật thông tin ngân hàng của <span style={notificationStyle}>{data}</span> thất bại!
                    </>
                )
                break
            case 'deleteBankPartner':
                description = (
                    <>
                        Xoá tài khoản ngân hàng của <span style={notificationStyle}>{data}</span> thất bại!
                    </>
                )
                break
            default:
                break
        }
    }

    messageApi.open({
        type: type,
        content: description,
        style: {
            cursor: 'pointer',
        },
    })
}

export default openMessageNotification
