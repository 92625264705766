import moment from 'moment'

// format number
export const formatNumber = (value) => {
    if (!value) return value
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const parseNumber = (value) => {
    if (!value) return value
    return value.toString().replace(/,/g, '')
}

//validate phone
export const phoneRegExp = /^[0-9]{10}$/
export const passRegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

//process status
export const processStatus = (data) => {
    switch (data.status) {
        case 0:
            return 'đã đặt'
        case 1:
            return Array.isArray(data.date) ? '' : 'đã giao nhân viên'
        case 2:
            return Array.isArray(data.date) ? 'đã thanh toán' : 'hoàn thành'
        case 3:
            return 'Người dùng huỷ'
        case 4:
            return 'Người lao động huỷ'
        case 5:
            return 'Admin huỷ'
        default:
            return ''
    }
}

export const processData = (apiData, page, pageSize, boxName) => {
    return apiData.map((item, index) => {
        let address
        switch (boxName) {
            case 'airCondition':
                address = item.address
                break
            case 'sofa':
                break
            default:
                address = item.taskId?.address ?? item.cleanId?.address
                break
        }
        return {
            ...item,
            index: index + 1 + page * pageSize,
            // address:  item.taskId?.address ?? item.cleanId?.address,
            address,
            status: processStatus(item),
            date: moment(item.date).format('DD-MM-YYYY'),
            dateRoot: item.date,
            estimatePeople: item?.cleanId?.estimatePeople,
            statusRoot: item.status,
        }
    })
}

export const getRowClassName = (params, highlightedId) => {
    let className = ''

    switch (params.row.status) {
        case 'Người dùng huỷ':
        case 'Người lao động huỷ':
        case 'Admin huỷ':
            className = 'cancelled-row'
            break
        case 'đã đặt':
            className = 'pending-row'
            break
        case 'hoàn thành':
            className = 'completed-row'
            break
        default:
            break
    }

    if (params.row._id === highlightedId) {
        className += ' blink-animation'
    }

    return className
}
