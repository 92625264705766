import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, useTheme, Tab, Tabs } from "@mui/material";
import { tokens } from "@src/theme";
import { Avatar, Button, Modal, Upload, Slider, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { baseURL } from "@utils/api/baseURL";
import { useDispatch } from "react-redux";
import { get_user_profile, update_profile } from "@utils/api/apiList";
import { QRCodeCanvas } from "qrcode.react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Header from "@components/Header";
import AvatarEditor from "react-avatar-editor";
import openMessageNotification from "@components/notification/message";
import getEventUploadAvatarSlice from "@redux/features/getEventUploadAvatarSlice";
import getEventUpdateProfileAdminSlice from "@redux/features/getEventUpdateProfileAdminSlice";
import request from "@utils/api/request";
import images from "@assets/images";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import TabProfile from "./tab_profile";
import TabAffiliate from "./tab_affiliate";
import TabBankAccount from "./tab_bank_acc";
import "./index.scss";
import "antd/dist/reset.css";
import { useLocation, useNavigate } from "react-router-dom";

//settup tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const matches = useMediaQuery("(min-width:900px)");

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={`tab-item-outline-${index}`}
      {...other}
      style={{ flex: 1 }}
    >
      {value === index && (
        <Box sx={{ padding: matches ? "32px" : "32px 0" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function KOLsProfile() {
  const location = useLocation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const matches = useMediaQuery("(min-width:900px)");
  const [userInfo, setUserInfo] = useState(null);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const navigate = useNavigate()

  //state redux
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const qrCodeRef = useRef(null);

  // upload antd
  const [uploadVisible, setUploadVisible] = useState(false);
  const [viewVisible, setViewVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [scale, setScale] = useState(1);
  const [errorFile, setErrorFile] = useState(null);
  const [initialProfileValues, setInitialProfileValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    bankAccountName: "",
  });

  //tabs
  const [valueTab, setValueTab] = useState(0);

  // handle change tab
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  //message
  const [messageApi, messageContextHolder] = message.useMessage();

  //validate upload
  const validateFileType = (file) => {
    const allowedTypes = ["image/jpeg", "image/png"]; // Các loại file cho phép
    if (!allowedTypes.includes(file.type)) {
      message.error(
        "File ảnh bạn đã tải lên không hợp lệ, định dạng được chấp nhận: JPG, PNG."
      );
      setErrorFile(true);
      return Upload.LIST_IGNORE; // Ngăn không cho file được upload
    }
    return true; // Cho phép upload nếu file hợp lệ
  };

  //fetchData Account
  const fetchData = () => {
    request.setAuthToken(token);
    request
      .post(get_user_profile, { info: { _id: userId } })
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        const avatar = baseURL + data?.avatar;

        setUserInfo({ ...data, avatarConfig: avatar });

        setInitialProfileValues({
          fullName: data?.fullName || "",
          email: data?.email || "",
          phoneNumber: (data?.phoneNumber[0] == '2' ? '0' + data?.phoneNumber.slice(1) : data?.phoneNumber) || "",

        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
    if (!!location?.state?.updateBankInfo) {
      setValueTab(1)
    }
  }, [location?.state?.updateBankInfo]);

  //updateProfile
  const fecthUpdateProfile = (data, resetForm, setFieldError) => {
    request
      .post(update_profile, { userInfo: { ...data } })
      .then((res) => {
        fetchData();
        openMessageNotification(
          messageApi,
          "success",
          "updateProfileAccountCompany",
          userInfo?.fullName
        );
        dispatch(
          getEventUpdateProfileAdminSlice.actions.eventUpdateProfileAdmin(
            res.data
          )
        );
      })
      .catch((err) => {
        const textErr = err.response.data.data;
        switch (textErr) {
          case "PhoneInUseInPartnerApp":
          case "PhoneInUseInUserApp":
          case "PhoneInUse":
            message.error("Số điện thoại đã được đăng ký!");
            setFieldError("phoneNumber", "Số điện thoại đã được đăng ký!");
            break;
          default:
            message.error("Cập nhật hồ sơ admin thất bại, vui lòng thử lại!");
            break;
        }

        console.error("Lỗi cập nhật profile doanhnghiep", err);
      })
      .finally(() => setLoadingProfile(false));
  };

  //upload
  const handleUpload = ({ file }) => {
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setScale(1); // Reset scale when a new image is uploaded
    };
    reader.readAsDataURL(file);
  };

  const handleOk = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      canvas.toBlob((blob) => {
        const formData = new FormData();
        formData.append("file", blob, "avatar.jpg");

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        fetch(baseURL + "users/upload_avatar", {
          method: "POST",
          headers: myHeaders,
          body: formData,
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.success) {
              // const avatar = baseURL + 'uploads/' + result.data
              // console.log(result)

              const avatar = result.data;
              const dataForm = {
                avatar,
              };
              const dataEventUpload = { data: userInfo, type: result.success };
              fecthUpdateProfile(dataForm);
              dispatch(
                getEventUploadAvatarSlice.actions.eventUpload(dataEventUpload)
              );
            }
            setImageUrl(URL.createObjectURL(blob));
            setUploadVisible(false);
          })
          .catch((error) => {
            console.error("Error uploading avatar:", error);
          });
      }, "image/jpeg");
    }
  };

  const handleCancel = () => {
    setUploadVisible(false);
    setPreviewImage(null);
    setErrorFile(false);
  };

  const handleSliderChange = (value) => {
    setScale(value);
  };

  const handleCopyLink = (refCode) => {
    navigator.clipboard.writeText("https://api.eclean.vn/?refCode=" + refCode);
    message.success("Copied!");
  };
  const downloadQr = (refCode) => {
    const canvas = qrCodeRef.current;
    if (canvas) {
      const pngUrl = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `QRCode-${refCode}.png`;
      downloadLink.click();
    }
  };

  const styleBorderPanel = {
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderColor: "#b6bbbc8a",
    padding: "20px 0px",
    borderRadius: 0,
  };

  return (
    <Box
      className='setting-kols'
      m="20px"
      sx={{
        "& .MuiTypography-root.MuiTypography-h2": {
          margin: "0 0 5px 0",
        },
        "& .MuiInputLabel-outlined, .MuiInputLabel-formControl.Mui-focused": {
          color: `${colors.greenAccent[400]}`,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: `${colors.grey[100]} !important`,
          borderWidth: "1px !important",
        },
        "& .MuiFilledInput-root::after": {
          borderColor: colors.grey[100],
        },
        "& .MuiFormControl-root": { mb: "20px", minWidth: "200px" },
        "& .MuiFilledInput-root::after, .MuiInput-underline::after": {
          borderColor: colors.greenAccent[400],
        },
      }}
    >
      {messageContextHolder}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="THÔNG TIN TÀI KHOẢN"
          subtitle="Cập nhật ảnh và thông tin cá nhân tại đây."
        />
      </Box>
      <Box
        height="auto"
        m="40px 0 0 0"
        p={{ xs: "20px", sm: "40px" }}
        minHeight="75vh"
        sx={{
          width: "100%",
          typography: "body1",
          bgcolor:
            theme.palette.mode === "dark"
              ? "rgb(75 93 131 / 38%)"
              : "#ffffff9c",
          borderRadius: "4px",
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        }}
      >
        <Box>
          <Box
            display="flex"
            gap={"20px"}
            alignItems={{ xs: "center", sm: "flex-start" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box style={{ position: "relative", display: "inline-block" }}>
              <Avatar
                src={
                  imageUrl || userInfo?.avatarConfig || images.placeholderPerson
                }
                size={128}
                onClick={() => setViewVisible(true)}
                style={{ cursor: "pointer" }}
              />
              <Button
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  borderRadius: "20px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "rgba(24, 24, 24,0.7)",
                  borderColor: "rgba(24, 24, 24,0.7)",
                }}
                icon={<CameraAltIcon style={{ color: "white" }} />}
                onClick={() => setUploadVisible(true)}
              />
            </Box>
            <Box flex={1} pt={2}>
              <Typography fontSize={"22px"} fontWeight="600">
                {userInfo?.fullName || userInfo?.email}
              </Typography>
              <Typography color={colors.grey[400]}>KOLs</Typography>
              <Box
                mt={1}
                display={"flex"}
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "flex-end" }}
              >
                <Typography mr={1} fontSize="16px">
                  Mã của bạn:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {userInfo?.refCode || "Chưa có"}
                  </span>
                </Typography>
                <Typography
                  color={theme.palette.mode === "dark" ? "#138567" : "#3da58a"}
                  fontWeight="600"
                  width="80px"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      color: "#17cb9c",
                    },
                  }}
                  onClick={() => handleCopyLink(userInfo?.refCode)}
                >
                  Copy Link
                </Typography>
              </Box>
              <Box
                mt={1}
                display={"flex"}
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "flex-end" }}
              >
                <Typography mr={1} fontSize="16px">
                  Điểm thưởng của bạn: {' '}
                  <span style={{ fontWeight: "600" }}>
                    {Intl.NumberFormat('en-US').format(userInfo?.point)}
                  </span>
                </Typography>
                {userInfo?.point > 0 && (
                  <Typography
                    color={
                      theme.palette.mode === "dark" ? "#138567" : "#3da58a"
                    }
                    fontWeight="600"
                    width="80px"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        color: "#17cb9c",
                      },
                    }}
                    onClick={() => { navigate('/kols-withdrawals-request', { state: { requestPoint: true } }) }}
                  >
                    Rút tiền
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              style={{
                position: "relative",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: "6px",
                  border:
                    theme.palette.mode === "dark"
                      ? "1px solid rgba(255, 255, 255, 0.23)"
                      : "1px solid rgba(0, 0, 0, 0.23)",
                  padding: "10px",
                  display: "inline-block",
                }}
              >
                <QRCodeCanvas
                  size={120}
                  value={"https://api.eclean.vn/?refCode=" + userInfo?.refCode}
                  marginSize={2}
                  ref={qrCodeRef}
                  level={"H"}
                />
              </Box>
              <Typography mt={1} color={colors.grey[200]}>
                Mã của bạn {userInfo?.refCode || "Chưa có"}
              </Typography>
              <Typography
                color={theme.palette.mode === "dark" ? "#138567" : "#3da58a"}
                fontWeight="600"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    color: "#17cb9c",
                  },
                }}
                onClick={() => downloadQr(userInfo?.refCode)}
              >
                Lưu QR Code
              </Typography>
            </Box>
          </Box>
          {/* Tabs - start */}
          <Box
            sx={{
              ...styleBorderPanel,
              flexGrow: 1,
              minHeight: "58vh",
              display: matches ? "flex" : "block",
              marginTop: "20px",
            }}
          >
            {/* Tab dọc: các menu setting chính */}
            <Tabs
              orientation={matches ? "vertical" : "horizontal"}
              variant="scrollable"
              value={valueTab}
              onChange={handleChangeTab}
              sx={{
                borderRight: matches ? 1 : 0,
                borderColor: "divider",
                width: matches ? "200px" : "100%",
                "& .MuiTabs-flexContainer .MuiButtonBase-root": {
                  fontWeight: "600",
                },
                "& .MuiTabs-vertical": {
                  flexShrink: 0,
                },
                "& .MuiTabs-vertical .tab-item-outline-1": {
                  width: "100%",
                  overflow: "hidden",
                },
                "& .MuiTab-root.Mui-selected": {
                  color:
                    theme.palette.mode === "dark"
                      ? "#acb0e6"
                      : colors.blueAccent[400],
                  bgcolor:
                    theme.palette.mode === "dark"
                      ? "rgb(76 87 130 / 35%)"
                      : "rgb(172 182 220 / 35%)",
                },
                "& .MuiTabs-indicator": {
                  bgcolor: colors.blueAccent[500],
                },
              }}
            >
              <Tab label="Thông tin" {...a11yProps(0)} />
              <Tab label="Thông tin ngân hàng" {...a11yProps(1)} />
              {/* <Tab label="Danh sách giới thiệu" {...a11yProps(2)} /> */}
            </Tabs>
            {/* Tab content: nội dung cuả các tab chính */}
            <TabPanel value={valueTab} index={0}>
              <TabProfile
                userInfo={userInfo || {}}
                initialProfileValues={initialProfileValues}
                getUserProfile={fetchData}
              />
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <TabBankAccount
                userId={userId || {}}
              />
            </TabPanel>
            {/* <TabPanel value={valueTab} index={2}>
              <TabAffiliate />
            </TabPanel> */}
          </Box>

          {/* Tabs - end */}
        </Box>
      </Box>

      <Modal
        open={uploadVisible}
        title="Upload Avatar"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            className="buttonAntdPrimary"
          >
            Upload
          </Button>,
        ]}
      >
        <Upload
          showUploadList={false}
          beforeUpload={(file) => {
            // Chỉ upload nếu file hợp lệ
            if (validateFileType(file)) {
              handleUpload({ file });
            }
            return false; // Chặn upload mặc định của Antd
          }}
        >
          <Button icon={<UploadOutlined />}>Select Image</Button>
        </Upload>
        {previewImage && !errorFile && (
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box display="flex" width={"100%"}>
              <AvatarEditor
                ref={editorRef}
                image={previewImage}
                width={370}
                height={370}
                border={50}
                borderRadius={999}
                scale={scale}
                rotate={0}
              />
            </Box>
            <Slider
              min={1}
              max={3}
              step={0.01}
              value={scale}
              onChange={handleSliderChange}
              style={{ marginTop: 16, width: "100%" }}
            />
          </Box>
        )}
      </Modal>
      <Modal
        open={viewVisible}
        title="View Avatar"
        footer={null}
        onCancel={() => setViewVisible(false)}
      >
        <img
          alt="Avatar"
          style={{ width: "100%" }}
          src={imageUrl || userInfo?.avatarConfig}
        />
      </Modal>
    </Box>
  );
}

export default KOLsProfile;
