import { useState, useEffect } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { Box, Drawer, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { tokens } from '../../theme'
import { get_user_profile } from '../../utils/api/apiList'
import { useSelector } from 'react-redux'
import { getEventUpdateProfileAdminSelector, getEventUploadAvatarSelector } from '../../redux/selectors'
import { baseURL } from '../../utils/api/baseURL'
import { routerLinks } from '../../routes/constant'
import GroupIcon from '@mui/icons-material/Group';
import RedeemIcon from '@mui/icons-material/Redeem';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import CloseIcon from '@mui/icons-material/Close'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import request from '../../utils/api/request'
import images from '../../assets/images'
import moment from 'moment'
import './style.scss'

function SidebarComponent({ isMobile, collapsed, onCollapsed }) {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [selected, setSelected] = useState('Trang chủ')
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const location = useLocation()

    // nhận sự kiện upload
    const dataEventUpload = useSelector(getEventUploadAvatarSelector)
    const dataUpdateProfileAdmin = useSelector(getEventUpdateProfileAdminSelector)

    //state data admin account
    const [userInfo, setUserInfo] = useState(null)
    const KOlsID = localStorage.getItem('userId')
    const token = localStorage.getItem('token')

    //active menu
    useEffect(() => {
        const path = location.pathname
        const pathToTitle = {
            [routerLinks.KOLsProfile]: 'Tài khoản',
            [routerLinks.KOLsStatisticsFee]: 'Thống kê',
            [routerLinks.KOLsStatisticsUser]: 'Người giới thiệu',
            [routerLinks.KOLsWithdrawalsHistory]: 'Lịch sử rút tiền',
            [routerLinks.AllReferals]: 'Danh sách toàn bộ',
            [routerLinks.PointReferals]: 'Danh sách điểm thưởng',
            [routerLinks.KOLsPolicy]: 'Điều khoản dịch vụ',
            [routerLinks.KOLsManager]: 'Cộng tác viên', 
            [routerLinks.Banner]: 'Quản lý banner',
            [routerLinks.Calendar]: 'Calendar',
            [routerLinks.Faq]: 'FAQ Page',
            [routerLinks.Bar]: 'Bar Chart',
            [routerLinks.Pie]: 'Pie Chart',
            [routerLinks.Line]: 'Line Chart',
            [routerLinks.Geography]: 'Geography Chart',
            default: 'Trang chủ',
        }
        const selectedTitle = pathToTitle[path] || pathToTitle.default
        setSelected(selectedTitle)
    }, [location])

    //menu item
    const Item = ({ title, to, icon }) => {
        const navigate = useNavigate()

        return isCollapsed ? (
            <Tooltip
                title={title}
                arrow
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -20],
                                },
                            },
                        ],
                    },
                }}
            >
                <Box>
                    <MenuItem
                        active={selected === title}
                        sx={{ color: colors.grey[100] }}
                        onClick={() => {
                            setSelected(title)
                            navigate(to)
                        }}
                        icon={icon}
                    >
                        {title}
                    </MenuItem>
                </Box>
            </Tooltip>
        ) : (
            <MenuItem
                active={selected === title}
                sx={{ color: colors.grey[100] }}
                onClick={() => {
                    setSelected(title)
                    navigate(to)
                }}
                icon={icon}
            >
                {title}
            </MenuItem>
        )
    }

    //fetch data company admin account
    const fetchUserInfo = () => {
        request.setAuthToken(token)
        request
            .post(get_user_profile, { info: { _id: KOlsID } })
            .then((res) => {
                const data = res.data.data
                const avatar = res.data.data?.avatar
                setUserInfo({ ...data, avatarConfig: baseURL + avatar })
                // console.log({ ...data, avatarConfig: baseURL + avatar, fullNameConfig: data.fullName })
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchUserInfo()
    }, [dataEventUpload, dataUpdateProfileAdmin])

    const onCollapsedMenu = () => {
        if (isMobile) {
            !!onCollapsed && onCollapsed()
        } else {
            setIsCollapsed(!isCollapsed)
        }
    }
    const renderMenu = (
        <Box
            sx={{
                '& .ps-sidebar-root': {
                    height: '100%',
                },
                '& .ps-sidebar-container': {
                    backgroundColor: colors.primary[400],
                },
                '& .ps-menu-button:hover': {
                    color: '#868dfb !important',
                    cursor: 'pointer',
                },
                '& .ps-menu-button.ps-active': {
                    color: '#6870fa !important',
                    background: theme.palette.mode === 'light' ? 'linear-gradient(to right, #ddd6f3, #ffedbc)' : 'unset',
                },
                '& .ps-submenu-content': {
                    bgcolor: colors.primary[400],
                },
                boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
                '& .ps-menu-label': {
                    fontSize: '14px !important',
                },
            }}
            className='wrapper-sidebar'
        >
            <Sidebar collapsed={isCollapsed}>
                <Menu>
                    <MenuItem
                        onClick={onCollapsedMenu}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 20px 0',
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box display='flex' justifyContent='space-between' alignItems='center' ml='15px'>
                                <Typography variant='h4' color={colors.grey[100]} fontWeight='900'>
                                    ECLEAN COMPANY
                                </Typography>
                                <IconButton onClick={onCollapsedMenu}>{isMobile ? <CloseIcon /> : <MenuOutlinedIcon />}</IconButton>
                            </Box>
                        )}
                    </MenuItem>
                    {!isCollapsed && (
                    <Box mb='25px'>
                        <Box display='flex' justifyContent='center' alignItems='center'>
                            <img
                                alt='avatar-admin'
                                src={userInfo?.avatarConfig || images.placeholderPerson}
                                style={{
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    width: 100,
                                    height: 100,
                                }}
                            />
                        </Box>
                        <Box textAlign='center'>
                            <Typography variant='h5' color={colors.grey[100]} fontWeight='bold' sx={{ m: '10px 0 0 0' }}>
                                Xin chào
                            </Typography>
                            <Typography variant='h3' color={colors.grey[100]} fontWeight='bold' sx={{ m: '5px 0 0 0' }}>
                                {userInfo?.fullName || userInfo?.email.split("@")[0]}
                            </Typography>
                        </Box>
                    </Box> )}
                    <Box
                        sx={{
                            '& .ps-menu-button .css-havevq-MuiSvgIcon-root.iconCustom': {
                                fill: 'none',
                                width: '18px',
                                height: '18px',
                            },
                            '& .ps-menu-icon.ps-active': {
                                '& .iconCustom path': { stroke: '#6870fa' },
                            },
                            '& .ps-menu-button:hover ': {
                                '& .iconCustom': {
                                    '& path': { stroke: '#6870fa' },
                                },
                            },
                        }}
                    >
                        {/* <Typography variant='h6' color={colors.grey[300]} sx={{ m: isCollapsed ? '5px 0 5px 20px' : '5px 0 5px 28px' }}>
                            Phần của CTV(dùng để test)
                        </Typography> */}
                        <Item title='Tổng quan' to='/' icon={<HomeOutlinedIcon />} />
                        <Item title='Tài khoản' to={routerLinks.KOLsProfile} icon={<FolderSharedIcon />} />
                        <SubMenu label='Người giới thiệu' icon={<GroupIcon />}>
                            <Item title='Danh sách toàn bộ' to={routerLinks.AllReferals} icon={<GroupIcon />} />
                            <Item title='Danh sách điểm thưởng' to={routerLinks.PointReferals} icon={<RedeemIcon />} />
                        </SubMenu>
                        <SubMenu label='Quản lý rút tiền' icon={<AccountBalanceWalletIcon />}>
                            <Item title='Lịch sử rút tiền' to={routerLinks.KOLsWithdrawalsHistory} icon={<AccountBalanceWalletIcon />} />
                            <Item title='Yêu cầu rút tiền' to={routerLinks.KOLsWithdrawalsRequest} icon={<AccountBalanceWalletIcon />} />
                        </SubMenu>
                        <Item title='Thống kê' to={routerLinks.KOLsStatisticsFee} icon={<EqualizerOutlinedIcon />} />
                        <Item
                            title='Điều khoản dịch vụ'
                            to={routerLinks.KOLsPolicy}
                            icon={<InventoryOutlinedIcon />}
                        />
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    )
    return isMobile ? (
        <Drawer open={collapsed} onClose={onCollapsed}>
            {renderMenu}
        </Drawer>
    ) : (
        renderMenu
    )
}

export default SidebarComponent
