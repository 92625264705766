import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { get_all_policy } from "@src/utils/api/apiList";
import { tokens } from "@src/theme";
import parse from "html-react-parser";
import Header from "@components/Header";
import request from "@src/utils/api/request";

const KOLsPolicy = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [policy, setPolicy] = useState("");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    request
      .post(get_all_policy, {})
      .then((response) => {
        const policies = response.data.data.docs;
        const policiesFilter = policies.find(
          (policy) => policy.stringLink === "kolpolicy"
        );
        setPolicy(policiesFilter);
      })
      .catch((error) => {
        console.error("Lỗi get all dữ liệu Policy", error);
      });
  };

  return (
    <Box
      m="20px"
      sx={{
        "& .MuiTypography-root.MuiTypography-h2": {
          margin: "0 0 5px 0",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="ĐIỀU KHOẢN VÀ DỊCH VỤ"
          subtitle="Trang điều khoản dịch vụ"
        />
      </Box>
      <Box
        sx={{
          color: "white",
          // "& h1": {
          //   display: "none",
          // },
          "& h1, h2,h3,h4,h5,p,span": {
            fontSize: "16px",
            color:
              theme.palette.mode === "dark"
                ? "white!important"
                : "rgba(0, 0, 0, 0.87)!important",
          },
          "& .container": {
            maxWidth: "100%!important",
            width: "100%!important",
          },
          padding: {xs: '30px 0', md: '30px 30px'}
        }}
      >
        <Box mx={'auto'} padding={'20px'} maxWidth={{xs: '100%', lg: '60vw'}} bgcolor={colors.primary[400]} boxShadow={theme.palette.mode === 'dark' ? '0 4px 6px 0 #0f1625' : '0 4px 6px 0 #f2f2f2'}>
        {parse(policy?.content || "")}
        </Box>
      </Box>
    </Box>
  );
};

export default KOLsPolicy;
