import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  useTheme,
  IconButton,
  Button,
  Chip,
  debounce,
  Switch,
  Tooltip,
} from "@mui/material";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header";
import StyleDataGrid from "../../assets/styles/styleDataGrid";
import StyleButtonGradient from "../../assets/styles/styleButtonGradient";
import request from "../../utils/api/request";
import moment from "moment";
import * as yup from "yup";
import EmojiFlagsOutlinedIcon from "@mui/icons-material/EmojiFlagsOutlined";
import {
  create_banner,
  delete_banner,
  get_list_banner,
  update_banner,
} from "../../utils/api/apiList";
import { Image, message } from "antd";
import { baseURL } from "../../utils/api/baseURL";
import ModalBanner from "./modalBanner";
import BoxDelete from "../../components/popover";
import DataGridCustom from "@src/components/datagrid";
import useQueryOptions from "@src/components/datagrid/useQueryOptions";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Banner = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const role = localStorage.getItem("role");
  const [currentRow, setCurrentRow] = useState(null);
  const [listBanner, setListBanner] = useState(null);
  const [ancholElAdd, setAncholElAdd] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ancholElEdit, setAncholElEdit] = useState(null);
  const [ancholElDelete, setAncholElDelete] = useState(null);
  const [checkedState, setCheckedState] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);

  //message
  const [messageApi, messageContextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  // pagination server side
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [totalRows, setTotalRows] = useState(0);
  //sort server

  //validate box add
  const validateSchema = yup.object().shape({
    title: yup.string().required("tiêu đề là bắt buộc"),
    description: yup.string().required("không được để trống"),
    thumbnail: yup.string().required("ảnh banner là bắt buộc"),
    // type: yup.number().typeError('phải là số').required('không được bỏ trống'),
  });

  //form state create
  const [formState, setFormState] = useState({
    title: "",
    description: "",
    thumbnail: "",
    type: Number,
    codeDiscount: "",
  });

  //form state edit
  const [formStateEdit, setFormStateEdit] = useState({
    title: "",
    description: "",
    thumbnail: "",
    type: Number,
    codeDiscount: "",
  });

  // Debounced
  const debouncedSetSearchTerm = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 500),
    []
  );

  // sort server
  const [queryOptions, handleSortModelChange] = useQueryOptions({
    createdDate: -1,
  });

  //fetching
  useEffect(() => {
    fetchData();
  }, [paginationModel, queryOptions]);

  //fetch data
  const fetchData = () => {
    setLoading(true);

    request
      .post(get_list_banner, {
        page: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        sort: queryOptions,
      })
      .then((res) => {
        const data = res.data.data;
        const convertData = data.docs.map((elm, index) => {
          let activeConvert;
          switch (elm.active) {
            case 0:
              activeConvert = "Tắt";
              break;
            case 1:
              activeConvert = "Bật";
              break;
            default:
              break;
          }

          return {
            ...elm,
            index: index + 1 + paginationModel.page * paginationModel.pageSize,
            dateConvert: moment(elm.createdDate).format("MM-DD-YYYY"),
            activeConvert,
          };
        });

        setListBanner(convertData);
        setCheckedState(() => {
          const initialState = {};
          convertData.forEach((row) => {
            initialState[row._id] = row.active === 1;
          });
          return initialState;
        });
        setTotalRows(data.total);

        // console.log(convertData)
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //fetch delete
  const fetchDelete = (_id) => {
    request
      .post(delete_banner, { _id })
      .then((res) => {
        message.success("Xoá banner thành công");
        fetchData();
      })
      .catch((err) => {
        message.error("Lỗi: xoá banner thất bại!");
        console.error(err);
      })
      .finally(() => setAncholElDelete(false));
  };

  //fetch edit
  const fetchEdit = (values) => {
    request
      .post(update_banner, { ...values })
      .then((res) => {
        message.success("Cập nhật banner thành công!");
        fetchData();
        handleCloseEdit();
      })
      .catch((err) => {
        console.error(err);
        message.error("Lỗi: cập nhật banner thất bại!");
      })
      .finally(() => setIsLoading(false));
  };

  //fetch Create
  const fetchCreate = (values, resetForm) => {
    request
      .post(create_banner, { ...values, active: 0 })
      .then((res) => {
        message.success("Tạo banner thành công!");
        fetchData();
        resetForm();
        handleCloseBoxCreate();
      })
      .catch((err) => {
        console.error(err);
        message.error("Lỗi: tạo banner thất bại!");
      })
      .finally(() => setIsLoading(false));
  };

  //column
  const columns = useMemo(
    () =>
      [
        {
          field: "index",
          headerName: "STT",
          width: 70,
          sortable: false,
        },
        {
          field: "codeDiscount",
          headerName: "Mã khuyến mãi",
          minWidth: 150,
          flex: 1,
          cellClassName: "name-column--cell--notTask maxValue",
          sortable: false,
        },
        {
          field: "title",
          headerName: "Tiêu đề",
          minWidth: 150,
          flex: 1,
          sortable: false,
        },
        {
          field: "description",
          headerName: "Mô tả",
          minWidth: 150,
          flex: 1,
          sortable: false,
        },
        {
          field: "thumbnail",
          headerName: "Ảnh banner",
          minWidth: 100,
          flex: 1,
          renderCell: ({ row }) => {
            return (
              <Image
                src={baseURL + "uploads/" + row.thumbnail}
                alt="anh banner"
              ></Image>
            );
          },
          sortable: false,
        },
        {
          field: "dateConvert",
          headerName: "Ngày tạo",
          minWidth: 100,
          flex: 0.5,
        },
        {
          field: "activeConvert",
          headerName: "Trạng thái",
          minWidth: 100,
          flex: 0.8,
          renderCell: ({ row }) => {
            return (
              <Chip
                size="small"
                label={row.activeConvert}
                sx={{
                  backgroundColor:
                    row.active === 0 ? colors.grey[600] : "green",
                  color: "white",
                  fontWeight: 700,
                  minWidth: "80px",
                  fontSize: "12px",
                }}
              />
            );
          },
        },
        role === "admin" && {
          field: "action",
          headerName: "Hành động",
          minWidth: 200,
          flex: 1,
          sortable: false,
          renderCell: ({ row }) => (
            <Box display="flex" alignItems="center" gap={1}>
              <Tooltip
                title={checkedState[row._id] ? "Bật" : "Tắt"}
                color={checkedState[row._id] ? "success" : "default"}
              >
                <Switch
                  size="small"
                  checked={checkedState[row._id] || false}
                  onChange={(e) => handleChangeSwitch(e, row)}
                />
              </Tooltip>
              <IconButton
                onClick={() => handleOpenEdit(row)}
                aria-label="edit"
                sx={{
                  color: colors.orangeAccent[500],
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton onClick={(event) => handleOpenBoxDelete(row, event)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ),
        },
      ].filter(Boolean),
    [listBanner]
  );

  //handleChange switch
  const handleChangeSwitch = (e, row) => {
    const { checked } = e.target;
    setCheckedState((prevState) => ({
      ...prevState,
      [row._id]: checked,
    }));

    delete row.activeConvert;
    delete row.dateConvert;
    handleSubmitEdit({
      ...row,
      active: checked ? 1 : 0,
    });
  };

  //edit
  const handleOpenEdit = (row) => {
    setCurrentRow(row);
    setAncholElEdit(true);
    setFormStateEdit({
      ...row,
    });
  };
  const handleCloseEdit = () => {
    setAncholElEdit(false);
    setPreviewImage(null);
    setFormStateEdit({});
  };

  const handleSubmitEdit = (values) => {
    console.log("values", values);
    setIsLoading(true);
    const timer = setTimeout(() => {
      fetchEdit(values);
    }, 300);

    return () => clearTimeout(timer);
  };

  //delete
  const handleOpenBoxDelete = (row, e) => {
    setAncholElDelete(e.currentTarget);
    setCurrentRow(row);
  };

  const handleCloseBoxDelete = () => {
    setAncholElDelete(false);
  };

  const handleConfirmDelete = () => {
    fetchDelete(currentRow._id);
  };

  //create banner
  const handleOpenBoxCreate = (e) => {
    setAncholElAdd(e.currentTarget);
  };

  const handleCloseBoxCreate = () => {
    setAncholElAdd(false);
    setPreviewImage(null);
  };

  const handleSubmitCreate = (values, { resetForm }) => {
    // console.log('values', values)

    setIsLoading(true);
    const timer = setTimeout(() => {
      fetchCreate(values, resetForm);
    }, 300);

    return () => clearTimeout(timer);
  };

  //upload banner
  const beforeUpload = (file, setFieldValue) => {
    // Kiểm tra định dạng file
    const allowedTypes = ["image/jpeg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      message.error(
        `File ảnh bạn đã tải lên không hợp lệ, định dạng được chấp nhận: JPG, PNG.`
      );
      return Upload.LIST_IGNORE; // Ngăn không cho file được upload
    }

    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const formData = new FormData();
    formData.append("file", file);

    fetch(baseURL + "banners/upload_thumbnail", {
      method: "POST",
      headers,
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          message.success("Upload thành công!");
          setFieldValue("thumbnail", result.data);
          setPreviewImage(result.data);
        } else {
          message.error("Upload thất bại!");
        }
      })
      .catch((error) => {
        console.error("Error uploading banner:", error);
        message.error("Upload thất bại!");
      });

    // Ngăn chặn hành động mặc định của antd Upload
    return false;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewOpen(true);
  };

  return (
    <Box
      m="20px"
      sx={{
        "& .MuiTypography-root.MuiTypography-h2": {
          margin: "0 0 5px 0",
        },
      }}
    >
      {messageContextHolder}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="BANNER" subtitle="Trang quản lý banner" />
        {role === "admin" && (
          <Button
            sx={StyleButtonGradient()}
            onClick={(e) => handleOpenBoxCreate(e)}
          >
            <EmojiFlagsOutlinedIcon sx={{ mr: "10px" }} />
            TẠO BANNER
          </Button>
        )}
      </Box>
      <Box
        height="75vh"
        m="40px 0 0 0"
        sx={{
          ...StyleDataGrid(),
          "& .maxValue": {
            color: theme.palette.mode === "dark" ? "#44c144" : "#49ac49",
          },
        }}
      >
        <DataGridCustom
          rows={listBanner}
          rowCount={totalRows}
          columns={columns}
          initialState={{
            pagination: { paginationModel },
            // density: 'comfortable',
          }}
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={handleSortModelChange}
          loading={loading}
        />
      </Box>

      {role === "admin" && (
        <>
          <ModalBanner
            type={"create"}
            open={Boolean(ancholElAdd)}
            onClose={handleCloseBoxCreate}
            isLoading={isLoading}
            handlePreview={handlePreview}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
            setPreviewImage={setPreviewImage}
            previewImage={previewImage}
            formState={formState}
            validateSchema={validateSchema}
            beforeUpload={beforeUpload}
            handleConfirm={handleSubmitCreate}
          />

          <ModalBanner
            type={"edit"}
            open={Boolean(ancholElEdit)}
            onClose={handleCloseEdit}
            isLoading={isLoading}
            handlePreview={handlePreview}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
            setPreviewImage={setPreviewImage}
            previewImage={previewImage}
            formState={formStateEdit}
            validateSchema={validateSchema}
            beforeUpload={beforeUpload}
            handleConfirm={handleSubmitEdit}
          />

          <BoxDelete
            type="delete"
            idRow={currentRow?._id}
            anchorEl={ancholElDelete}
            onClose={handleCloseBoxDelete}
            handleConfirm={handleConfirmDelete}
            boxName={"banner"}
          />
        </>
      )}
    </Box>
  );
};

export default Banner;
