// Import the functions you need from the SDKs you need
import { getAuth } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyAbAJ5WLvrl5Rl5LNDGJN1jY5GU1BAfD5g',
    authDomain: 'test-b9dec.firebaseapp.com',
    projectId: 'test-b9dec',
    storageBucket: 'test-b9dec.appspot.com',
    messagingSenderId: '1000257632515',
    appId: '1:1000257632515:web:e7d7144ed7b0f428ee5136',
    measurementId: 'G-XSYWLZPB0X',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

