import { useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { tokens } from "../../theme";
import { memo, useState } from "react";
import CustomNoRowsOverlay from "@components/noRows";
import CustomLoadingComponent from "@components/loading";
import useQueryOptions from "./useQueryOptions";
function DataGridCustom({
  getRowId = (row) => row._id,
  rows,
  totalRows,
  columns,
  initialState,
  paginationModel,
  pageSizeOptions = [10, 25, 50, 100],
  paginationMode = "server",
  sortingMode = "server",
  loading,
  ...props
  // có thể thêm những props thuộc DataGrid
}) {
  const theme = useTheme();
  //   const colors = tokens(theme.palette.mode);
  // thêm dòng này để khi cần thay đổi createdDate, queryOptions 
//   const [queryOptions, handleSortModelChange] = useQueryOptions({
//     createdDate: -1,
//   });

  return (
    <DataGrid
      getRowId={getRowId}
      rows={rows}
      rowCount={totalRows}
      columns={columns}
      //pagination server side
      pagination
      initialState={initialState}
      pageSizeOptions={pageSizeOptions}
      paginationMode={paginationMode}
      // sort server
      sortingMode={sortingMode}
      loading={loading}
      slots={{
        toolbar: GridToolbar,
        noRowsOverlay: CustomNoRowsOverlay,
        loadingOverlay: CustomLoadingComponent,
      }}
      {...props}
    />
  );
}

export default DataGridCustom;
