import { routerLinks } from './constant'

import CompanyDashboard from '../scenes/dashboard/companyDashboard'
import BlankPage from '../scenes/blank'
import AdminLayout from '../layout/AdminLayout'
import LoginLayout from '../layout/loginLayout/LoginLayout'
import Login from './Login'
import Banner from '../scenes/banner'

import Dashboard from './KOLs/Dashboard'
import KOLsProfile from './KOLs/KOLsProfile'
import KOLsStatisticsFee from './KOLs/KOLsStatisticsFee'
import KOLsStatisticsUser from './KOLs/KOLsStatisticsUser'
import KOLsWithdrawalsHistory from './KOLs/KOLsWithdrawalsHistory'
import KOLsWithdrawalsRequest from './KOLs/KOLsWithdrawalsRequest'
import KOLsPolicy from './KOLs/KOLsPolicy'
import AllReferals from './KOLs/AllReferals'
import PointReferals from './KOLs/PointReferals'
const role = localStorage.getItem('role')

// Routes cho admin
const adminRoutes = [
    {
        path: routerLinks.Dashboard,
        component: Dashboard,
        layout: AdminLayout,
    },

    {
        path: routerLinks.KOLsProfile,
        component: KOLsProfile,
        layout: AdminLayout,
    },
    {
        path: routerLinks.KOLsStatisticsFee,
        component: KOLsStatisticsFee,
        layout: AdminLayout,
    },
    {
        path: routerLinks.KOLsStatisticsUser,
        component: KOLsStatisticsUser,
        layout: AdminLayout,
    },
    {
        path: routerLinks.KOLsWithdrawalsHistory,
        component: KOLsWithdrawalsHistory,
        layout: AdminLayout,
    },
    {
        path: routerLinks.KOLsWithdrawalsRequest,
        component: KOLsWithdrawalsRequest,
        layout: AdminLayout,
    },
    {
        path: routerLinks.KOLsPolicy,
        component: KOLsPolicy,
        layout: AdminLayout,
    },
    {
        path: routerLinks.AllReferals,
        component: AllReferals,
        layout: AdminLayout,
    },
    {
        path: routerLinks.PointReferals,
        component: PointReferals,
        layout: AdminLayout,
    },
    {
        path: routerLinks.Banner,
        component: Banner,
        layout: AdminLayout,
    },
]

// trang blank
const commonRoutes = [
    {
        path: '*',
        component: BlankPage,
        layout: AdminLayout,
    },
]

// Tùy theo vai trò mà áp dụng các route tương ứng
let publicRoutes = [...adminRoutes, ...commonRoutes]

// if (role === 'admin') {
//     publicRoutes = [...adminRoutes, ...commonRoutes]
// } else if (role === 'doanhnghiep') {
//     publicRoutes = [...ctvRoutes, ...companyRoutes, ...commonRoutes]
// } else {
//     publicRoutes = [...commonRoutes]
// }

//Private routes
const privateRoutes = [
    {
        path: routerLinks.Login,
        component: Login,
        layout: LoginLayout,
    },
]

export { publicRoutes, privateRoutes }
