//server global
// export const baseURL = 'https://api.eclean.vn/'
// export const baseIdCompany = '6662bce9a94adad42b78325a'
export const baseURL = 'https://api.eclean.dev/'
export const baseIdCompany = '6662bce9a94adad42b78325a'

// export const baseURL = 'https://apifordev.pdteam.net/'
// export const baseIdCompany = '6662bce9a94adad42b78325a'

// server pdteam
// export const baseURL = 'https://apitasks.pdteam.net/'
// export const baseIdCompany = '660d0f656dde561a28ba5dbd'
