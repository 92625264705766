import { useTheme } from '@mui/material'
import { tokens } from '../theme'
import { mockLineData as data } from '../data/mockData'
import { ResponsiveLine } from '@nivo/line'
import { formatNumber } from './prefixData'

function LineChart({ dataChart, nameChart, companyName, selectedDate, multiLine }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const renderLegendBottom = () => {
        switch (nameChart) {
            //doanh nghiep
            case 'statisticsUser': 
                return `Số người giới thiệu từ ${selectedDate}`
            case 'revenueByKOLs': 
                return `Doanh thu của bạn năm ${selectedDate}`
            case 'revenue':
                return `Doanh thu công ty ${companyName} năm ${selectedDate.year()}`
            case 'revenueDateByDate':
                return `Doanh thu công ty ${companyName} ${selectedDate}`
            case 'profit':
                return `Lợi nhuận công ty ${companyName} năm ${selectedDate.year()}`
            case 'profitDateByDate':
                return `Lợi nhuận công ty ${companyName} ${selectedDate}`
            case 'totalOrder':
                return `Đơn hàng công ty ${companyName} năm ${selectedDate.year()}`
            case 'totalOrderDateByDate':
                return `Đơn hàng công ty ${companyName} ${selectedDate}`
            //nạp cọc
            case 'depositDateByDate':
                return `Tiền nạp cọc công ty ${companyName} từ ${selectedDate}`
            case 'depositCountDateByDate':
                return `Só lượt nạp cọc công ty ${companyName} từ ${selectedDate}`
            // ca le
            case 'TaskRevenue':
                return `Doanh thu ca lẻ năm ${selectedDate.year()}`
            case 'TaskProfit':
                return `Lợi nhuận ca lẻ năm ${selectedDate.year()}`
            case 'TotalOrderTask':
                return `Đơn hàng ca lẻ năm ${selectedDate.year()}`
            //tvs
            case 'CleanRevenue':
                return `Doanh thu tổng vệ sinh năm ${selectedDate.year()}`
            case 'CleanProfit':
                return `Lợi nhuận tổng vệ sinh năm ${selectedDate.year()}`
            case 'TotalOrderClean':
                return `Đơn hàng tổng vệ sinh năm ${selectedDate.year()}`
            //co dinh
            case 'PermanentRevenue':
                return `Doanh thu ca cố định năm ${selectedDate.year()}`
            case 'PermanentProfit':
                return `Lợi nhuận ca cố định năm ${selectedDate.year()}`
            case 'TotalOrderPermanent':
                return `Đơn hàng ca cố định năm ${selectedDate.year()}`
            //dieu hoa
            case 'AirRevenue':
                return `Doanh thu điều hoà năm ${selectedDate.year()}`
            case 'AirProfit':
                return `Lợi nhuận điều hoà năm ${selectedDate.year()}`
            case 'TotalOrderAir':
                return `Đơn hàng điều hoà năm ${selectedDate.year()}`
            //sofa
            case 'SofaRevenue':
                return `Doanh thu sofa năm ${selectedDate.year()}`
            case 'SofaProfit':
                return `Lợi nhuận sofa năm ${selectedDate.year()}`
            case 'TotalOrderSofa':
                return `Đơn hàng sofa năm ${selectedDate.year()}`
            //phunkhukhuan
            case 'SprayRevenue':
                return `Doanh thu phun khử khuẩn năm ${selectedDate.year()}`
            case 'SprayProfit':
                return `Lợi nhuận phun khử khuẩn năm ${selectedDate.year()}`
            case 'TotalOrderSpray':
                return `Đơn hàng phun khử khuẩn năm ${selectedDate.year()}`
             //kols
            case 'refDataByDate': 
                return `Số lượt giới thiệu ${selectedDate}`
            case 'pointDataByDate': 
                return `Số lượt nhận thưởng ${selectedDate}`
            default:
                return 'biểu đồ'
        }
    }

    const renderLegendLeft = () => {
        switch (nameChart) {
            case 'revenueByKOLs':
            case 'revenue':
            case 'revenueDateByDate':
            case 'profit':
            case 'profitDateByDate':
            case 'depositDateByDate':
            case 'TaskRevenue':
            case 'CleanRevenue':
            case 'PermanentRevenue':
            case 'AirRevenue':
            case 'SofaRevenue':
            case 'SprayRevenue':
            case 'TaskProfit':
            case 'CleanProfit':
            case 'PermanentProfit':
            case 'SofaProfit':
            case 'AirProfit':
            case 'SprayProfit':
                return 'Chỉ số (x 1.000.000 triệu đồng)'
            case 'totalOrder':
                return 'chỉ số'
            case 'refDataByDate':
                return 'Số lượng'
            case 'pointDataByDate':
                return 'Chỉ số (x 1.000.000)'
            default:
                return 'chỉ số'
        }
    }

    //color
    const colorsColumnChart = {
        'Doanh thu': colors.greenLight[400],
        'Lợi nhuận': colors.greenLight[400],
        'Tiền cọc': colors.greenLight[400],
        'Đơn hàng': '#ff7f0e',
        'Số lượt nạp': '#ff7f0e',
    }
    const getColor = (bar) => {
        return colorsColumnChart[bar.id]
    }
    return (
        <ResponsiveLine
            data={dataChart}
            theme={{
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },
                    legend: {
                        text: {
                            fill: colors.greenAccent[500],
                            fontSize: '14px',
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                    },
                },
                tooltip: {
                    container: {
                        color: colors.primary[500],
                    },
                },
            }}
            enableGridX={false}
            // colors={{ scheme: 'nivo' }}
            // colors={dataChart ? (multiLine ? {scheme: 'nivo'} : getColor): { scheme: 'nivo' }}
            colors={{ scheme: 'category10' }}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false,
            }}
            // yFormat=' >-.2f'
            // curve='catmullRom'
            yFormat=' >-$d'
            curve='monotoneX'
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: renderLegendBottom(),
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
            }}
            axisLeft={{
                tickSize: 5,
                tickValues: 5, // số lượng value hiển thị trên trục Y
                tickPadding: 5,
                tickRotation: 0,
                legend: renderLegendLeft(),
                legendOffset: -40,
                legendPosition: 'middle',
                truncateTickAt: 0,
            }}
            gridYValues={3}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: multiLine ? 'left-to-right': 'top-to-bottom',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            lineWidth={1} //độ rộng line
            tooltip={({ point }) => {
                // Define colors and other styling properties
                const getColor = (chartType) => {
                    if (chartType.includes('Profit')) {
                        return '#ff7f0e' // Color for profit chart
                    }
                    return chartType.includes('Order') || chartType === 'depositCountDateByDate' ? '#ff7f0e' : colors.greenLight[400]
                }

                // Define a helper to format the y-value based on the chart type
                const formatYValue = (chartType, yValue) => {
                    if (
                        chartType.includes('Revenue') ||
                        chartType.includes('Profit') ||
                        chartType === 'revenue' ||
                        chartType === 'profit' ||
                        chartType === 'revenueDateByDate' ||
                        chartType === 'profitDateByDate' ||
                        chartType === 'depositDateByDate' ||
                        chartType === 'refDataByDate'||
                        chartType === 'pointDataByDate'
                    ) {
                        return `${formatNumber(yValue.toFixed(3) * 1000000)} VNĐ`
                    } else if (chartType.includes('Order')) {
                        return yValue
                    }
                    return yValue
                }

                return (
                    <div
                        style={{
                            borderRadius: '0.75rem',
                            border: '1px solid #e5e7eb',
                            backgroundColor: '#ffffff',
                            padding: '1rem',
                            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            <div
                                style={{
                                    width: '0.25rem',
                                    borderRadius: '0.25rem',
                                    alignSelf: 'stretch',
                                    backgroundColor: getColor(nameChart),
                                }}
                            ></div>
                            <div style={{ flex: 1 }}>
                                <strong
                                    style={{
                                        display: 'block',
                                        fontWeight: 500,
                                        fontSize: '1rem',
                                        lineHeight: '1rem',
                                        color: '#9d99a8',
                                    }}
                                >
                                    {point.serieId}
                                </strong>
                                <span
                                    style={{
                                        fontSize: '1.125rem',
                                        lineHeight: '1.875rem',
                                        color: '#1e1c24',
                                    }}
                                >
                                    {point.data.xFormatted}: <span style={{ fontWeight: 600 }}>{formatYValue(nameChart, point.data.y)}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
}

export default LineChart
